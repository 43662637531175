.detail-page {
    background-color: #fff;
}

.detail-page .logo img {
    height: 50px;
    position: relative;
    top: 20px;
    left: 20px;
    z-index: 3;
}

.project-detail {
    margin: 0 auto;
    padding: 40px;
    font-family: Arial, sans-serif;
}

.project-detail .info {
    padding: 50px 0;
}

.project-detail .info .title {
    font-size: 8rem;
    text-transform: uppercase;
    line-height: 1;
    font-weight: bold;
}

.project-detail .info .description {
    font-size: 2rem;
    font-weight: 100;
}

/* Masonry using column-count trick */
.media-masonry {
    column-count: 3;
    /* 3 columns on desktop */
    column-gap: 15px;
}

/* Each item: images or iframes */
.media-item {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
}

/* Make sure images and videos fit well */
.media-item img,
.media-item iframe {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    /* If you have consistent aspect ratio images */
}

/* Make videos take full height dynamically */
.media-item iframe {
    width: 100%;
    height: 100%;
    /* Ensures it fills the container */
    aspect-ratio: 4.8/3.2;
    /* Maintains 16:9 ratio */
}

.back-button-container {
    text-align: center;
    margin: 30px 0;
}

.back-button {
    display: inline-block;
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    transition: background 0.3s ease;
}

.back-button:hover {
    background-color: #ff7000;
}


/* Responsive Adjustments */
@media (max-width: 1024px) {
    .project-detail {
        padding: 10px;
    }

    .project-detail .info .title {
        font-size: 4rem;
        text-transform: uppercase;
        line-height: 1;
        font-weight: bold;
    }

    .project-detail .info .description {
        font-size: 1.5rem;
        font-weight: 100;
    }

    .media-masonry {
        column-count: 2;
    }
}

@media (max-width: 768px) {
    .project-detail {
        padding: 10px;
    }

    .project-detail .info .title {
        font-size: 2.3rem;
        text-transform: uppercase;
        line-height: 1;
        font-weight: bold;
    }

    .project-detail .info .description {
        font-size: 1.1rem;
        font-weight: 100;
    }

    .media-masonry {
        column-count: 1;
    }
}

@media (max-height: 450px) {
    .project-detail .info {
        text-align: center;
    }

    .project-detail .info .title {
        font-size: 2rem;
        text-transform: uppercase;
        line-height: 1;
        font-weight: bold;
    }

    .project-detail .info .description {
        font-size: 2rem;
        font-weight: 100;
    }

    .media-masonry {
        column-count: 1;
    }
}