.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #808080;
    /* Change the background color as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000;
    opacity: 1;
    transition: opacity 0.5s ease;
    overflow: hidden;
}

.preloader-content {
    color: #fff;
    /* Change the text color as needed */
    font-size: 2rem;
    text-align: center;
}

.preloader-logo {
    opacity: 0;
}