.not-found-section {
    height: 100vh;
    padding: 20px;
    background-color: #fff;
}

.not-found-section img {
    height: 70px;
    position: absolute;
    top: 20px;
    left: 20px;
}

.not-found-info {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    height: 100%;
    background-color: #fff;
}

.section-one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 50px;
    width: 800px;
    float: left;
    background-color: #fff;
}

.section-one .links {
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    line-height: 1;
}

.links .item-link {
    transition: 0.5s;
    text-decoration: none;
    color: #000;
}

.section-one .links .item-link:hover:not(:first-child) {
    font-weight: bold;
    color: #ff7000;
    font-size: 2.3rem;

}

.section-two .links .item-link:hover {
    font-size: 8rem;
    font-weight: bold;
    color: #ff7000;
}


.section-one .big-link {
    font-size: 7rem;
    font-weight: bold;
    line-height: 0.8;
    transition: 0.3s;

}

.section-one .big-link:hover {
    color: #ff7000;
}


.section-one .email {
    text-decoration: underline;
    font-size: 1.5rem;
    font-weight: 100;
    transition: 0.3s;
    color: #000;
}

.section-one .email:hover {
    color: #ff7000;
    font-weight: bold;
}

.section-one .socials a {
    font-weight: 100;
    padding-right: 10px;
    transition: 0.3s;
    text-decoration: none;
    color: #000;
}

.section-one .socials a:hover {
    font-weight: bold;
}

.section-two {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 0 50px;
}

.section-two .links {
    display: flex;
    flex-direction: column;
    font-size: 7rem;
    line-height: 0.8;
}

.section-two .links a {
    font-weight: bold;
}

.section-two .bottom a {
    padding-right: 10px;
    font-weight: 100;
    transition: 0.3s;
    text-decoration: none;
    color: #000;
}

.section-two .bottom a:hover {
    font-weight: bold;
}

.middle-mobile {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}

.middle-mobile .email {
    text-decoration: underline;
    font-size: 2rem;
    transition: 0.3s;
    color: #000;
}

.middle-mobile .email:hover {
    color: #ff7000;
}

.middle-mobile .socials a {
    margin-right: 15px;
    font-weight: 100;
    transition: 0.3s;
    font-size: 1.5rem;
    text-decoration: none;
    color: #000;
}

.middle-mobile .socials a:hover {
    font-weight: bold;
}

#hide-desktop {
    display: none;
}

/* RESPONSIVE DESIGN */

/* Tablet (max-width: 1024px) */
@media (max-width: 1024px) {
    .not-found-section {
        padding: 0;
        height: 100%;
    }

    .not-found-info {
        flex-direction: column;
        align-items: flex-start;
    }

    .section-one,
    .section-two,
    .middle-mobile {
        width: 100%;
        height: 100%;
        padding: 15px;
        margin-top: 80px;
    }

    .not-found-section img {
        position: relative;
        height: 70px;
        top: 30px;
        left: 30px;
    }

    .section-one .big-link {
        font-size: 6.5rem;
    }

    .section-one .links {
        font-size: 2.5rem;
    }

    .section-one .links .item-link:hover:not(:first-child) {
        font-weight: bold;
        color: #ff7000;
        font-size: 3rem;
    }

    .section-one .email {
        font-size: 1rem;
    }

    .section-two .links {
        font-size: 7rem;
        text-align: left;
    }

    .section-two .links .item-link:hover {
        font-size: 7rem;
    }

    .section-two .bottom a {
        font-size: 0.9rem;
    }

    #hide-mobile {
        display: none;
    }

    #hide-desktop {
        display: flex;
    }
}

/* Mobile (max-width: 768px) */
@media (max-width: 768px) {
    .not-found-section {
        padding: 0;
        height: 100%;
    }

    .not-found-section img {
        position: relative;
        height: 50px;
        top: 10px;
        left: 10px;
    }

    .section-one,
    .section-two,
    .middle-mobile {
        width: 100%;
        height: 100%;
        padding: 15px;
        margin-top: 110px;
    }

    .section-one .big-link {
        font-size: 5rem;
    }

    .section-one .links {
        font-size: 2rem;
    }

    .section-one .links .item-link:hover:not(:first-child) {
        font-weight: bold;
        color: #ff7000;
        font-size: 2rem;
    }

    .section-one .email {
        font-size: 1rem;
    }

    .section-two .links {
        font-size: 6rem;
        text-align: left;
    }

    .section-two .links .item-link:hover {
        font-size: 6rem;
    }

    .section-two .bottom a {
        font-size: 0.9rem;
    }

    #hide-mobile {
        display: none;
    }

    #hide-desktop {
        display: flex;
    }
}

@media (max-width: 480px) {
    .not-found-section {
        padding: 0;
        height: 100%;
    }

    .not-found-section img {
        position: relative;
        height: 50px;
        top: 25px;
        left: 25px;
    }

    .section-one,
    .section-two,
    .middle-mobile {
        width: 100%;
        height: 100%;
        padding: 15px;
        margin-top: 110px;
    }

    .section-one .big-link {
        font-size: 4rem;
    }

    .section-one .links {
        font-size: 2rem;
    }

    .section-one .links .item-link:hover:not(:first-child) {
        font-weight: bold;
        color: #ff7000;
        font-size: 2rem;
    }

    .section-one .email {
        font-size: 1rem;
    }

    .section-two .links {
        font-size: 4rem;
        text-align: left;
    }

    .section-two .links .item-link:hover {
        font-size: 4rem;
    }

    .section-two .bottom a {
        font-size: 0.9rem;
    }

    .middle-mobile .socials a {
        margin-right: 10px;
        font-size: 1.2rem;
    }

    #hide-mobile {
        display: none;
    }

    #hide-desktop {
        display: flex;
    }
}