/* General Styles */
.navbar {
    position: relative;
}

.menu-btn {
    background: none;
    border: none;
    cursor: pointer;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 3;
    color: #ffffff;
    mix-blend-mode: difference;
}

/* Overlay */
.nav__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    display: none;
    z-index: 9;
}

/* Menu */
.nav__menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 500px;
    height: 100vh;
    background: white;
    transform: translateX(100%);
    padding: 40px;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.close-btn {
    align-self: flex-end;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}

/* Links */
.nav__links {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.nav__link {
    font-size: 5rem;
    text-decoration: none;
    color: black;
    margin-bottom: 15px;
    position: relative;
    transition: color 0.3s;
}

.nav__linkInner {
    display: inline-block;
    transition: transform 0.3s;
}

.nav__link:hover .nav__linkInner {
    transform: translateX(5px);
    color: #ff7000;
}

/* Buttons */
.nav__buttons {
    margin-top: 40px;
}

.nav__menuButton {
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 1.5rem;
    border: 1px solid black;
    text-decoration: none;
    color: black;
    transition: background 0.3s;
}

/* Footer */
.nav__menuButton:hover {
    background: #ff7000;
}

/* Footer */
.nav__footer {
    margin-top: auto;
    font-size: 1rem;
    text-align: center;
    font-weight: 100;
}

.nav__footer span {
    font-weight: 100;
}

.nav__footer a {
    font-weight: 100;
    text-decoration: none;
    color: #000;
}

@media (max-width: 1024px) {
    .nav__menu {
        padding: 20px;
        width: 100%;
    }

    .nav__links {
        margin-top: 20%;
    }

    .nav__link {
        font-size: 4rem;
    }
}

/* Mobile (max-width: 768px) */
@media (max-width: 768px) {
    .nav__menu {
        padding: 20px;
        width: 100%;
    }

    .nav__links {
        margin-top: 20%;
    }

    .nav__link {
        font-size: 4rem;
    }
}

@media (max-height: 450px) {
    .nav__menu {
        padding: 20px;
        width: 100%;
    }

    .nav__links {
        margin-top: 0;
    }

    .nav__link {
        font-size: 3rem;
        margin-bottom: 0;
    }

    .nav__buttons {
        margin-top: 0;
    }
}