.projects-page .logo img {
    height: 50px;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 3;
}

.projects-hero {
    position: relative;
    width: 100%;
    height: 70vh;
    display: flex;
    color: white;
    overflow: hidden;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 50px;
}

.projects-hero .banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    /* Adjust the opacity (0 = transparent, 1 = fully black) */
    z-index: 2;
}

.projects-logo {
    margin: auto auto;
    z-index: 3;
}

.projects-logo img {
    height: 250px;
    /* Adjust size */
    width: auto;
}

.projects-hero .banner-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url("../img/client_imgs/Millworx-1.jpg") center/cover no-repeat;
    z-index: -2;
}

/* Background Video */
.projects-hero .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    /* Ensures full width */
    height: 100vh;
    /* Ensures full height */
    object-fit: cover;
    /* Prevents white gaps */
    z-index: -3;
    opacity: 0;
}

.projects-hero .background-video.loaded {
    opacity: 1;
}

.project-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none;
}

.projects-logo {
    margin: auto auto;
    z-index: 3;
}

.projects-logo img {
    height: 250px;
    /* Adjust size */
    width: auto;
}

.projects-section {
    padding: 50px 0;
    background-color: #fff;
}

.projects-section-title {
    text-align: center;
    font-size: 8em;
    line-height: 1;
    font-weight: bold;
}

.projects-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.projects-filter button {
    font-size: 2rem;
    margin: 20px;
    transition: 0.3s;
    border: none;
    background: none;
    cursor: pointer;
    color: #000;
}

.projects-filter button:hover {
    font-size: 2rem;
    color: #ff7000;
}

.projects-filter button.active {
    text-decoration: underline;
    color: #ff7000;
    font-weight: bold;
    transform: scale(1.1);
}

.projects {
    padding: 50px 10px;
    background-color: #fff;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two equal columns */
    gap: 10px;
    width: 100%;
}

.project-item {
    position: relative;
    overflow: hidden;
}

.project-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ff7000;
    transform: translateY(100%);
    z-index: 1;
}

.project-item img {
    width: 100%;
    height: auto;
    display: block;
}

.project-info {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    padding: 10px;
    line-height: 1;
}

.project-info h3 {
    font-size: 2.5em;
    font-weight: bold;
}

.project-info p {
    font-size: 1.3em;
    font-weight: 100;
}

.project-wide {
    grid-column: span 2;
    height: 600px;
}

.project-wide img {
    transform: translateY(-35%) scale(1.2);
}

.project-image,
.project-video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.load-more-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.load-more {
    background: black;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
}

.load-more:hover {
    background: #333;
}

.more-projects-section {
    padding: 40px;
}

.more-projects-section h2 {
    font-size: 8rem;
    margin-bottom: 20px;
    line-height: 1;
    font-weight: bold;
}

.more-projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    width: 100%;
    margin: auto;
}

/* Individual Project Item */
.more-projects-grid .project-item {
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    transition: 0.5s;
}

.more-projects-grid .project-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: 0.5s;
}

/* Hover Effect */
.more-projects-grid .project-item:hover {
    transform: scale(1.02);
}


@media (max-width: 1024px) {
    .projects-section-title {
        font-size: 4rem;
        line-height: 0.5;
    }

    .projects-grid {
        display: flex;
        flex-wrap: wrap;
    }

    .project-wide {
        height: 100%;
    }

    .project-wide img {
        transform: translateY(-0%) scale(1);
    }

    .more-projects-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .projects-section-title {
        font-size: 4rem;
        line-height: 0.5;
    }

    .projects-grid {
        display: flex;
        flex-wrap: wrap;
    }

    .project-wide {
        height: 100%;
    }

    .more-projects-section h2 {
        font-size: 3.5rem;
        line-height: 0.9;
    }

    .project-wide img {
        transform: translateY(-0%) scale(1);
    }
}

@media (max-width: 480px) {
    .projects {
        padding: 10px;
    }

    .projects-hero .banner-overlay {
        background: rgba(0, 0, 0, 0.2);
    }

    .projects-section-title {
        font-size: 3.5rem;
        line-height: 0.5;
    }

    .project-info h3 {
        font-size: 1.5rem;
    }

    .project-info p {
        font-size: 1rem;
    }

    .project-wide {
        height: 100%;
    }

    .project-wide img {
        transform: translateY(-0%) scale(1);
    }

    .more-projects-section h2 {
        font-size: 3.5rem;
        line-height: 0.9;
    }

    .more-projects-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .projects-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 30px 0;
    }

    .projects-filter button {
        font-size: 2rem;
        margin: 10px;
        transition: 0.3s;
        border: none;
        background: none;
        cursor: pointer;
        color: #000;
    }

    .more-projects-section {
        padding: 10px;
    }
}