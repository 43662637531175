.services-page {
    margin: 0 auto;
    background-color: #fff;
}

.services-page .logo img {
    height: 50px;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 3;
}


.services-intro {
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px;
    max-width: 1800px;
}

.services-intro p {
    font-size: 2.1rem;
    font-weight: bold;
    color: #000;
}

.service-marquee {
    padding: 0 20px;
    text-transform: uppercase;
    font-size: 5rem;
    font-weight: bold;
}

.services-wrapper {
    padding: 0px;
}

.services-header {
    padding: 20px 60px;
}

/* Container for all services */
.services-container {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
}

/* Each service row */
.service-item {
    display: grid;
    grid-template-columns: 0.2fr 550px 0.9fr;
    /* Adjust as needed */
    border-top: 1px solid #e5e5e5;
    height: 25vh;
    align-items: center;
    /* Space between columns */
}

/* Left Column: Number */
.service-number {
    font-size: 1.4rem;
    font-weight: bold;
    color: #333;
    text-align: left;
}

/* Middle Column: Title */
.service-title {
    font-size: 4.5rem;
    font-weight: bold;
    color: #000;
    justify-content: flex-start;
    line-height: 0.8;
}

/* Right Column: Description */
.service-description {
    font-size: 1.5rem;
    color: #555;
    text-align: left;
}

/* Call-to-Action Section */
.services-cta {
    border-top: 1px solid #e5e5e5;
    padding: 50px 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.cta-text {
    font-size: 1.5rem;
    color: #444;
}

.cta-button {
    background-color: #000;
    color: #fff;
    padding: 12px 30px;
    font-size: 1.3rem;
    text-decoration: none;
    transition: background 0.3s ease;
}

.cta-button:hover {
    background-color: #ff7000;
}

/* -------------- Responsive Breakpoints -------------- */

/* ~1480px screens */
@media (max-width: 1480px) {
    .service-title {
        font-size: 1.6rem;
    }

    .service-description {
        font-size: 0.95rem;
    }
}

/* ~1024px screens */
@media (max-width: 1024px) {
    .service-item {
        grid-template-columns: 70px 150px 1fr;
    }

    .service-title {
        font-size: 1.4rem;
    }
}

/* ~768px screens */
@media (max-width: 768px) {
    .services-intro {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
        max-width: 100%;
        padding: 100px 20px 50px 20px;
    }

    .services-intro p {
        font-size: 1.6rem;
        text-align: justify;
    }

    .service-marquee {
        padding: 0 5px;
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: bold;
    }

    .services-header {
        padding: 20px;
        font-size: 1rem;
    }

    .services-container {
        max-width: 100%;
        padding: 0 20px;
    }

    .service-item {
        grid-template-columns: 50px 1fr;
        grid-template-rows: auto auto;
    }

    .service-title {
        font-size: 1.5rem;
    }

    .service-number {
        grid-row: 1;
        grid-column: 1;
    }

    .service-title {
        grid-row: 1;
        grid-column: 2;
    }

    .service-description {
        grid-row: 2;
        grid-column: 2;
        font-size: 1rem;
        padding-bottom: 20px;
    }
}

/* ~480px screens */
@media (max-width: 480px) {
    .services-intro {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
        max-width: 100%;
        padding: 100px 20px 50px 20px;
    }

    .services-intro p {
        font-size: 1.6rem;
        text-align: justify;
    }

    .service-marquee {
        padding: 0 5px;
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: bold;
    }

    .services-header {
        padding: 20px;
        font-size: 1rem;
    }

    .services-container {
        max-width: 100%;
        padding: 0 20px;
    }

    .service-item {
        grid-template-columns: 50px 1fr;
        grid-template-rows: auto auto;
    }

    .service-title {
        font-size: 1.5rem;
    }

    .service-number {
        grid-row: 1;
        grid-column: 1;
    }

    .service-title {
        grid-row: 1;
        grid-column: 2;
    }

    .service-description {
        grid-row: 2;
        grid-column: 2;
        font-size: 1rem;
        padding-bottom: 20px;
    }

    .services-cta {
        flex-direction: column;
        padding: 10px;
    }

    .cta-text {
        font-size: 1rem;
    }

    .cta-button {
        padding: 10px 20px;
        font-size: 1rem;
    }
}