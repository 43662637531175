.contact-page {
    width: 100%;
    height: 100vh;
}

.contact-page a img {
    height: 50px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
}

/* Contact Container */
.contact-page .contact-container {
    height: 100%;
    display: flex;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
}

/* Contact Info */
.contact-info {
    flex: 1;
    padding-left: 40px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.contact-info h1 {
    font-size: 6.5rem;
}


.contact-info .contact-link,
.contact-number {
    display: block;
    font-size: 2.3rem;
    margin: 5px 0;
    text-decoration: none;
    color: #000;
    border-bottom: 2px solid black;
    width: fit-content;
    font-weight: 100;
    line-height: 1.2;
    transition: 0.3s ease;
}

.contact-info .contact-link:hover,
.contact-number:hover {
    color: #ff7000;
    cursor: pointer;
}

.subtext {
    font-size: 1.5rem;
    margin-top: 20px;
    color: #555;
    font-weight: 100;
}

/* Contact Form */
.contact-form {
    flex: 1;
    padding-right: 40px;
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    margin-top: 80px;
}

.contact-form h2 {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.form-subtext {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

input,
textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    background: none;
}

textarea {
    height: 125px;
    resize: none;
}

.contact-form form button {
    width: 100%;
    padding: 12px;
    background: black;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
}

.contact-form form button:hover {
    background: #ff7000;
}

.success-message {
    color: #28a745;
    font-size: 16px;
    margin-top: 10px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.success-message.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 1480px) {
    .contact-info {
        padding: 50px;
    }

    .contact-form {
        padding: 50px;
    }

    .contact-info h1 {
        font-size: 5rem;
    }

    .contact-form h2 {
        font-size: 2rem;
    }
}

@media (max-width: 1024px) {
    .contact-page {
        width: 100%;
        height: 100%;
    }

    .contact-page .contact-container {
        height: 100%;
        display: flex;
        background: #fff;
        border-radius: 8px;
        overflow: hidden;
        flex-direction: column;
        padding-top: 80px;
    }

    .contact-info {
        padding: 40px;
        line-height: 1;
    }

    .contact-info h1 {
        font-size: 4rem;
    }

    .contact-info .subtext {
        font-size: 1.2rem;
    }

    .contact-info .contact-link,
    .contact-number {
        font-size: 2rem;
    }

    .contact-form {
        margin-top: 0;
        padding: 40px;
    }
}

@media (max-width: 768px) {
    .contact-page {
        width: 100%;
        height: 100%;
    }

    .contact-page .contact-container {
        height: 100%;
        display: flex;
        background: #fff;
        border-radius: 8px;
        overflow: hidden;
        flex-direction: column;
        padding-top: 80px;
    }

    .contact-info {
        padding: 20px;
    }


    .contact-info h1 {
        font-size: 4rem;
    }

    .contact-info .subtext {
        font-size: 1rem;
    }

    .contact-info .contact-link,
    .contact-number {
        font-size: 1.5rem;
    }

    .contact-form h2 {
        font-size: 2.5rem;
    }

    .contact-form {
        margin-top: 0;
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .contact-page {
        width: 100%;
        height: 100%;
    }

    .contact-page .contact-container {
        height: 100%;
        display: flex;
        background: #fff;
        border-radius: 8px;
        overflow: hidden;
        flex-direction: column;
        padding-top: 80px;
    }

    .contact-info {
        padding: 20px;
    }

    .contact-form h2 {
        font-size: 1.6rem;
    }

    .contact-info h1 {
        font-size: 3rem;
    }

    .contact-info .subtext {
        font-size: 1rem;
    }

    .contact-info .contact-link,
    .contact-number {
        font-size: 1.5rem;
    }

    .contact-form {
        margin-top: 0;
        padding: 20px;
    }
}

/* HEIGHT RESPONSIVE */
@media (max-height: 450px) {}