/* Footer.css */

/* General footer styling */
.footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 40px 60px;
    background-color: #ffffff;
    color: #000;
    font-size: 20px;
}

.footer-section {
    display: flex;
    justify-content: space-between;
    padding: 0 0 100px 0;
}

.footer-section a {
    color: #000;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
}

.footer-section a:hover {
    color: #FF7300;
}

.footer-links {
    display: flex;
    font-weight: 500;
    flex-direction: column;
}

.footer-links a {
    text-decoration: none;
}

.footer-links p {
    display: flex;
    font-weight: 500;
    flex-direction: column;
}

.footer-title {
    font-size: 3.5em;
    font-weight: bold;
    max-width: 1000px;
    line-height: 1.1;
}

.footer-link {
    color: #FF7300;
    text-decoration: underline;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-socials {
    display: flex;
    flex-direction: column;
}

.footer-socials a {
    text-decoration: none;
}

/* Footer bottom section */
.footer-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 16px;
    padding-top: 10px;

}

.footer-bottom a,
span {
    text-decoration: none;
    color: #000;
    font-weight: 500;
}

.footer-bottom a:hover {
    color: #FF7300;
}

#hide-desktop {
    display: none;
}

/* RESPONSIVE DESIGN */

/* Tablet (max-width: 1024px) */
@media (max-width: 1024px) {
    .footer {
        padding: 20px;
    }

    .footer-section {
        padding-bottom: 50px;
    }

    .footer-title {
        font-size: 2em;
        padding-left: 20px;
        max-width: 600px;
    }
}

/* Mobile (max-width: 768px) */
@media (max-width: 768px) {
    .footer {
        padding: 20px;
        font-size: 20px;
    }

    .footer-section {
        padding-bottom: 30px;
    }

    .footer-links,
    .footer-socials {
        margin-bottom: 20px;
    }

    .footer-title {
        font-size: 2em;
        max-width: 100%;
        text-align: justify;
        padding: 0;
    }

    .footer-bottom {
        font-size: 16px;
    }

    #hide-mobile {
        display: none;
    }

    #hide-desktop {
        display: flex;
    }
}