@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Arimo", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

::-moz-selection {
  background: #ff7000;
}

::-webkit-selection {
  background: #ff7000;
}

::selection {
  background: #ff7000;
}

/* Hero Section */
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  color: #fff;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 50px;
  padding-top: 120px;
}

.hero-section .banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity (0 = transparent, 1 = fully black) */
  z-index: -1;
}

.hero-logo {
  position: absolute;
  top: 30px;
  left: 40px;
}

.hero-logo img {
  height: 65px;
  /* Adjust size */
  width: auto;
}

.hero-section .banner-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url("./img/client_imgs/CGR.jpg") center/cover no-repeat;
  z-index: -2;
}

/* Background Video */
.hero-section .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  /* Ensures full width */
  height: 100vh;
  /* Ensures full height */
  object-fit: cover;
  /* Prevents white gaps */
  z-index: -3;
  opacity: 0;
}

.hero-section .background-video.loaded {
  opacity: 1;
}

/* Text Content */

.hero-text span {
  position: relative;
  font-size: 6rem;
  /* Large text */
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  line-height: 0.85;
  display: flex;
  flex-direction: column;
  /* Stacks spans on top of each other */
}

/* Subtext */
.hero-subtext {
  font-size: 1.4rem;
  font-weight: 400;
  max-width: 450px;
  text-align: justify;
  line-height: 1;
  padding: 20px 0;
}

/* Contact Button */
.hero-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #FF7300;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}

.hero-button:hover {
  background-color: #1d1d1d;
}

/* SERVICE SECTION */
.services-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 60px 20px;
  background-color: white;
}

/* Large Title */
.services-section-title {
  font-size: 8.5rem;
  font-weight: bolder;
  text-transform: uppercase;
  line-height: 1;
}

/* Highlight the word "production" */
.highlight {
  color: #FF7300;
  font-weight: bolder;
  text-decoration: underline;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100vh;
}

.projects-recap {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.projects-recap .video {
  background-color: #000000;
  height: 600px;
  width: 90%;
  margin-bottom: 50px;
}

.projects-recap .video .video-overlay {
  background-color: rgba(0, 0, 0, 0.801);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  height: 600px;
  width: 90%;
  opacity: 1;
  transition: 1s;
}

.projects-recap .video .video-overlay div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.projects-recap .video .video-overlay div p {
  padding: 10px;
}

.projects-recap .video .video-overlay div svg {
  font-size: 2em;
  padding: 2px;
}

.projects-section-title {
  text-align: center;
  font-size: 8em;
  line-height: 1;
  font-weight: bold;
}

.projects {
  padding: 10px;
  background-color: #fff;
}

.projects-grid {
  display: grid;
  /* Two equal columns */
  grid-gap: 10px;
}

.project-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.project-item img {
  width: 100%;
  height: auto;
  display: block;
}

.project-info {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  padding: 10px;
  line-height: 1;
}

.project-info h3 {
  font-size: 2.5em;
  font-weight: bold;
}

.project-info p {
  font-size: 1.3em;
  font-weight: 100;
}

.project-wide {
  grid-column: span 2;
  height: 500px;
}

.project-wide img {
  transform: translateY(-35%) scale(1.2);
}

.project-image,
.project-video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.worked-with-section {
  background-color: #fff;
}

.worked-with-section .clients {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 70px;
}

.worked-with-section .clients .worked-with {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.worked-with-section .clients .worked-with .top {
  font-size: 6em;
}

.worked-with-section .clients .worked-with .bottom {
  font-size: 4em;
  font-family: 'Courgette', cursive;
  font-weight: 100;
}

.worked-with-section .clients .worked-with .bottom span {
  font-family: 'Courgette', cursive;
  font-weight: 100;
}

.worked-with-section .clients .worked-with .bottom .underline {
  text-decoration: line-through;
}

.worked-with-section .clients .worked-with .bottom .color {
  color: #FF7300;
}

.worked-with-section .marquee-clients {
  padding: 50px;
}

.worked-with-section .marquee-clients img {
  height: 175px;
  padding: 20px;
  filter: grayscale(1);
  transition: 0.4s;
}

.worked-with-section .marquee-clients img:hover {
  filter: grayscale(0);
}

/* ContactSection.css */

.contact-section {
  position: relative;
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  overflow: hidden;
}

/* Background Image */
.contact-image {
  position: absolute;
  top: 50%;
  /* Move the image up by 50% of its container */
  left: 50%;
  width: 100%;
  height: 100%;
  background: url('./img/team/kmixcvisuals-1.jpg') no-repeat center center/cover;
  filter: grayscale(100%);
  transform: translate(-50%, -50%);
  /* Centers the image */
  z-index: -2;
}

/* Dark Overlay */
.contact-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  /* Adjust opacity to control darkness */
  z-index: -1;
}

/* Content Styling */
.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content h2 {
  font-size: 7rem;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 0.85;
}

.content-text {
  display: flex;
  margin-top: -20px;
}

.content-text p {
  max-width: 500px;
  text-align: justify;
  font-size: 0.8rem;
  line-height: 1.1;
  margin-bottom: 30px;
  font-weight: 100;
}

/* Circular Button */
.contact-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 200px;
  color: white;
  background-color: #ff6600;
  text-decoration: none;
  transition: background 0.3s ease-in-out;
  margin: 20px 40px;
}

.contact-button p {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 1;
  margin: 0;
  color: #e2eaf3;
  font-size: 0.9em;
  font-weight: bold;
  text-decoration: none;
  font-weight: 200;
}

.contact-button:hover {
  background-color: #e05500;
}

#hide-desktop {
  display: none;
}

@media (max-width: 1400px) {
  .hero-text span {
    font-size: 4.5rem;
  }
}

@media (max-width: 1024px) {
  .hero-section {
    padding: 20px;
    padding-top: 80px;
  }

  .hero-text span {
    font-size: 4.5rem;
  }

  .hero-subtext {
    font-size: 1.4rem;
    max-width: 500px;
    padding: 30px 0;
  }

  .hero-button {
    padding: 15px 25px;
    font-size: 0.9rem;
    font-size: 1.5rem;
    font-weight: 100;
  }

  .services-section {
    padding: 50px 20px;
  }

  .services-section-title {
    font-size: 3.5rem;
  }

  .services-section-links {
    gap: 10px;
    flex-wrap: wrap;
  }

  .projects-section-title {
    font-size: 4rem;
    line-height: 0.5;
  }

  .projects-recap .video {
    height: 400px;
    width: 90%;
  }

  .projects-recap .video .video-overlay {
    height: 400px;
    width: 90%;
    display: none;
  }

  .projects-grid {
    display: flex;
    flex-wrap: wrap;
  }

  .project-wide {
    height: 100%;
  }

  .project-wide img {
    transform: translateY(-0%) scale(1);
  }

  .worked-with-section .clients {
    padding: 10px;
  }

  .worked-with-section .clients .worked-with .top {
    font-size: 3.5rem;
  }

  .worked-with-section .clients .worked-with .bottom {
    font-size: 2rem;
    line-height: 0.3;
  }

  .contact-section {
    height: 400px;
  }

  .contact-section .content {
    padding: 0 20px;
  }

  .contact-section .content h2 {
    font-size: 2rem;
  }

  .contact-section .content p {
    font-size: 0.6rem;
  }

  .content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content-text p {
    margin-bottom: 0;
    padding: 10px;
  }

  .contact-button {
    font-size: 0.7em;
    margin: 0;
  }

  .worked-with-section .marquee-clients img {
    height: 125px;
    padding: 5px;
    filter: grayscale(1);
    transition: 0.4s;
  }

  #hide-mobile {
    display: none;
  }

  #hide-desktop {
    display: flex;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 20px;
    padding-top: 80px;
  }

  .hero-text span {
    font-size: 3.5rem;
  }

  .hero-subtext {
    font-size: 1rem;
    max-width: 300px;
  }

  .hero-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .services-section {
    padding: 50px 20px;
  }

  .services-section-title {
    font-size: 3.5rem;
  }

  .services-section-links {
    gap: 10px;
    flex-wrap: wrap;
  }

  .projects-section-title {
    font-size: 4rem;
    line-height: 0.5;
  }

  .projects-recap .video {
    height: 300px;
    width: 95%;
  }

  .projects-recap .video .video-overlay {
    height: 400px;
    width: 90%;
    display: none;
  }

  .projects-grid {
    display: flex;
    flex-wrap: wrap;
  }

  .project-wide {
    height: 100%;
  }

  .project-wide img {
    transform: translateY(-0%) scale(1);
  }

  .worked-with-section .clients {
    padding: 10px;
  }

  .worked-with-section .clients .worked-with .top {
    font-size: 3rem;
  }

  .worked-with-section .clients .worked-with .bottom {
    font-size: 1.8rem;
    line-height: 0.3;
  }

  .contact-section {
    height: 400px;
  }

  .contact-section .content {
    padding: 0 20px;
  }

  .contact-section .content h2 {
    font-size: 2rem;
  }

  .contact-section .content p {
    font-size: 0.6rem;
  }

  .content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content-text p {
    margin-bottom: 0;
    padding: 10px;
  }

  .contact-button {
    font-size: 0.7em;
    margin: 0;
  }

  .worked-with-section .marquee-clients img {
    height: 125px;
    padding: 5px;
    filter: grayscale(1);
    transition: 0.4s;
  }

  .home .overlay-transparent .countup-overlay {
    font-size: 8em;
    position: absolute;
    bottom: 0;
    padding: 20px;
    color: #fff;
    transform: translateX(50px);
    font-weight: bold;

  }

  .marquee-overlay {
    font-size: 3em;
    padding-left: 50px;
    color: #fff;
  }
}

@media (max-width: 480px) {
  .hero-section .banner-overlay {
    background: rgba(0, 0, 0, 0.2);
  }

  .hero-text span {
    font-size: 2.5rem;
  }

  .hero-subtext {
    font-size: 0.9rem;
    max-width: 300px;
    font-size: 0.9rem;
  }

  .hero-logo {
    top: 35px;
  }

  .hero-logo img {
    height: 50px;
  }

  .services-section-title {
    font-size: 1.7rem;
  }

  .hero-section {
    padding: 20px;
    padding-top: 80px;
  }

  .hero-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .services-section {
    padding: 50px 10px;
  }

  .projects-section-title {
    font-size: 3.5rem;
    line-height: 0.5;
  }

  .projects-recap .video {
    height: 300px;
    width: 100%;
  }

  .project-info h3 {
    font-size: 1.5rem;
  }

  .project-info p {
    font-size: 1rem;
  }

  .project-wide {
    height: 100%;
  }

  .project-wide img {
    transform: translateY(-0%) scale(1);
  }

  .worked-with-section .clients {
    padding: 10px;
  }

  .worked-with-section .clients .worked-with .top {
    font-size: 2.5rem;
  }

  .worked-with-section .clients .worked-with .bottom {
    font-size: 1.5rem;
    line-height: 0.3;
  }

  .contact-section .content {
    padding: 0 20px;
  }

  .contact-section .content h2 {
    font-size: 1.5rem;
  }

  .contact-section .content p {
    font-size: 0.5rem;
  }

  .content-text p {
    margin-bottom: 0;
    padding: 10px;
  }

  .contact-button {
    font-size: 0.7em;
    font-weight: 100;
    margin: 0;
    height: 40px;
    width: 150px;
  }

  .worked-with-section .marquee-clients img {
    height: 100px;
    padding: 5px;
    filter: grayscale(1);
    transition: 0.4s;
  }

  .home .overlay-transparent .countup-overlay {
    font-size: 5em;
    position: absolute;
    bottom: 0;
    padding: 20px;
    color: #fff;
    transform: translateX(50px);
    font-weight: bold;
  }

  .marquee-overlay {
    font-size: 3em;
    padding-left: 50px;
    color: #fff;
  }
}

/* HEIGHT RESPONSIVE */
@media (max-height: 450px) {
  .hero-logo img {
    height: 40px;
  }

  .hero-section {
    padding: 50px;
    padding-top: 100px;
  }

  .hero-text span {
    font-size: 2rem;
  }

  .hero-subtext {
    font-size: 0.9rem;
    padding: 10px 0;
  }

  .services-section-title {
    font-size: 3rem;
  }

  .projects-section-title {
    font-size: 3.5rem;
  }

  .services-section-links {
    gap: 30px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}