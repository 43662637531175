.page-transition {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    overflow: hidden;
}

.transition-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
}

.block1 {
    background: #858585;
    /* Change to your desired color */
}

.block2 {
    background: #ff7000;
    /* Change to your desired color */
}

.block3 {
    background: #ffffff;
    /* Change to your desired color */
}

.logo-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    /* Ensure the logo is above the blocks */
}

.transition-logo {
    width: 100px;
    /* Adjust the size as needed */
    height: 100px;
    /* Adjust the size as needed */
    opacity: 0;
    /* Initial opacity for animation */
}